import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from '../components/footer'
import ContactSection from '../components/ContactSection'
import {initMap} from '../utils/utils'
import DoctorInfo from '../components/DoctorInfo'
import michaelSzenteVarga006 from '../images/006.jpg'
import michaelSzenteVarga062 from '../images/062.jpg'
import michaelSzenteVarga163 from '../images/163.jpg'
import path1 from '../images/path-01-light.svg'
import path2 from '../images/path-02-light.svg'


const TitleSection = styled.div`
  margin: 160px 0 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  p {
    color: ${props => props.color ? props.color : 'initial'};
  }

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;
  color: ${props => props.color ? props.color : 'initial'};

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, ${props => props.color ? props.color : 'rgba(255,255,255,1)'} 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, ${props => props.color ? props.color : 'rgba(255,255,255,1)'} 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
`;

const Year = styled.p`
  color: #3c3c3c;

  @media(max-width: 1184px) {
    color: #c8d2ff;
  }
`;

const ContactSectionOuter = styled.div`
  background-color: #213fca;
  z-index: 1;
`;

const ContactSectionInner = styled.div`
  width: 90%;
  margin: 95px auto 0;
  position: relative;

  @media(min-width: 1920px) {
    width: 70%;
  }

  @media(min-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1184px) {
    width: 100%;
  }
`;

const renderCvContent = () => (<div className="cv-content-wrapper">
  <ul class="no-padding">
    <li>
      <Year><strong>2006 - heute</strong></Year>
      <p>Gefässchirurgische Praxis Klinik Hirslanden und Klinik Im Park, Zürich</p>
    </li>
    <li>
      <Year><strong>2004</strong></Year>
      <p>Vorstandsmitglied der Schweizerischen Gesellschaft für Gefässchirurgie (SSG)</p>
    </li>
    <li>
      <Year><strong>2002 - 2005</strong></Year>
      <p>Leiter der Abteilung für Gefässchirurgie
        Co-Chefarzt Chirurgie B (Herz-, Gefäss- und Thoraxchirurgie)
          Kantonsspital Luzern (Prof. P. Stulz)</p>
    </li>
    <li>
      <Year><strong>2001 - 2002</strong></Year>
      <p>Leitender Arzt und Leiter der Gefässchirurgie
          Herz- und Gefässchirurgie, Universitätsspital Zürich (Prof. M. Turina)</p>
    </li>
    <li>
      <Year><strong>2000</strong></Year>
      <p>Oberarzt, Herz- und Gefässchirurgie, Universitätsspital Zürich (Prof. M. Turina)</p>
    </li>
    <li>
      <Year><strong>1999</strong></Year>
      <p>Gastarzt, Thoraxchirurgie, Thoraxklinik Heidelberg, BRD (Prof. H. Dienemann)</p>
    </li>
    <li>
      <Year><strong>1996</strong></Year>
      <p>Visiting Fellow, Vascular Surgery, UCSF, San Francisco, CA, (Prof. Jerry Goldstone und Prof. Ronald J. Stoney)</p>
    </li>
    <li>
      <Year><strong>1996</strong></Year>
      <p> Visiting Fellow, Vascular Surgery, Albany Med. College, Albany, NY,(Prof. Robert P. Leather und Prof. Benjamin B. Chang)</p>
    </li>
    <li>
      <Year><strong>1995 - 2000</strong></Year>
      <p>Oberarzt, Chirurgie, Kantonsspital Baden (Prof. H. Säuberli)</p>
    </li>
    <li>
      <Year><strong>1994</strong></Year>
      <p>Assistenzarzt, Chirurgie, Kantonsspital Baden (Prof. H. Säuberli)</p>
    </li>
    <li>
      <Year><strong>1990 - 1993</strong></Year>
      <p>Assistenzarzt Chirurgie, Bezirksspital Zofingen (Dr. Fartab)</p>
    </li>
    <li>
      <Year><strong>1989</strong></Year>
      <p>Assistenzarzt Chirurgie, Kreisspital Rüti (Dr. P. Bleuler)</p>
    </li>
  </ul>
</div>);


class DrVargaPage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Header />
        <Layout
          background="#e0edff"
          images={[
            {
              path: path1,
              styles: {top: '300px', left: '30%'},
            },
            {
              path: path2,
              styles: {top: '40%', left: '20px'},
            }
          ]}
        >
          <SEO title="Dr. Varga" />

          <TitleSection color="#3c3c3c">
            <TagSection>
              <LineLeft color="#101f66" />
              <Tag color="#101f66">Dr. Michael Szente-Varga</Tag>
              <LineRight color="#101f66" />
            </TagSection>
            <Subtitle>Gefässspezialist Zürich</Subtitle>
            <br/>
            <p>Dr. Michael Szente Varga ist Facharzt für Chirurgie und Gründer des GefässZentrum Hirslanden Zürich sowie Mitbegründer des Aorten- und GefässZentrum Hirslanden Zürich. Ein Schwerpunkt seiner Tätigkeit liegt in der Behandlung der erweiterten Brust- und Bauchschlagader (Aneurysma) mittels so genannter Endovaskulärer Verfahren. Bis heute hat er weit über 1000 Patienten mit einer Endovaskulären Aortenprothese behandelt und ist an der Weiterentwicklung dieser Verfahren massgeblich beteiligt.</p>
          </TitleSection>

          <DoctorInfo 
            reverseColumns={false}
            name="Dr. med. Michael Szente Varga"
            text="Dr. med. Michael Szente Varga, der in Zürich Medizin studierte, absolvierte seine Ausbildung zur Chirurgie. Für den Facharzt Gefässchirurgie war er zusätzlich an der Vascular Surgery am UCSF in San Francisco und am Albany Medical College in New York, sowie in der Thoraxklinik Heidelberg tätig. Von 2000 bis 2002 war er Leiter der Gefässchirurgie am Universitätsspital Zürich und von 2002 bis 2005 war er Leiter der Abteilung für Gefässchirurgie und Co-Chefarzt Chirurgie am Kantonsspital Luzern. Seit 2006 ist er Belegarzt in der Klinik Hirslanden und Klinik Im Park, Zürich. Als Gefässchirurg ist er spezialisiert auf Durchblutungsstörungen der Becken- und Beinschlagadern, Verengungen der Halsschlagader, Erweiterungen der Bauchschlagader, Krampfadern, Thrombosen und offene Beine. Dabei wendet er verschiedene minimal-invasive Verfahren an, wie die Ballondilatation, Stenteinlagen, und die Krampfaderbehandlung. Seine operativen Verfahren umfassen Bypassoperationen, Thrombendarterektomien, der Ersatz der Bauchschlagader, das Anlegen von AV-Fisteln für die Dialyse sowie das Stripping von Krampfadern. Schwerpunktsmässig steht die Behandlung der Erweiterung der Brust- und Bauchschlagader mittels „Endovaskulären Verfahren“ im Vordergrund. Schon Mitte der 90er Jahre begab er sich zum Erlernen dieser neuen Methode ins Ausland bevor diese in der Schweiz eingeführt wurde. Bis heute hat er weit über 1000 Patienten mit einer „Endovaskulären Aortenprothese“ behandelt. Seit 4 Jahren ist er auch an der Entwicklung einer neuen endovaskulären Prothese involviert."
            headersColor="#101f66"
            textColor="#3c3c3c"
            infoButtonsColor="#1357df"
            cvContent={renderCvContent()}
            photo1={michaelSzenteVarga006}
            photo2={michaelSzenteVarga062}
            photo3={michaelSzenteVarga163}
            texts={[
              {
                tag: 'Dr. Szente Varga',
                title: 'Werdegang',
                text: 'Dr. med. Michael Szente Varga studierte in Zürich Medizin studierte und absolvierte seine Ausbildung zum Facharzt Chirurgie. Für den Facharzt Gefässchirurgie war er zusätzlich an der Vascular Surgery am UCSF in San Francisco und am Albany Medical College in New York, sowie in der Thoraxklinik Heidelberg tätig. Von 2000 bis 2002 war er Leiter der Gefässchirurgie am Universitätsspital Zürich und von 2002 bis 2005 war er Leiter der Abteilung für Gefässchirurgie und Co-Chefarzt Chirurgie am Kantonsspital Luzern. Seit 2006 ist er Belegarzt in der Klinik Hirslanden und Klinik Im Park Zürich.',
              },
              {
                tag: 'Dr. Szente Varga',
                title: 'Zahlen & Fakten',
                text: function() {return (<div className="content-wrapper">
                  <p>Bis heute hat Dr. Michael Szente Varga weit über 1000 Patienten mit einer Endovaskulären Aortenprothese behandelt.</p>
                  <br/>
                  <p>Schon Mitte der 90er Jahre begab er sich zum Erlernen dieser neuen Methode ins Ausland bevor diese in der Schweiz eingeführt wurde, wo er sie bis heute erfolgreich einsetzt. Seit 4 Jahren beteiligt er sich zudem an der Entwicklung einer neuen endovaskulären Prothesentechnologie.</p>
                  <br/>
                  <p>Dr. Michael Szente Varga ist Mitglied im Partnernetzwerk h-care.</p>
                </div>)}(),
              },
              {
                tag: 'Dr. Szente Varga',
                title: 'CV',
                text: renderCvContent(),
              },
              {
                tag: 'Dr. Szente Varga',
                title: 'Persönlich',
                text: '...',
                disabled: true,
              },
              {
                tag: 'Dr. Szente Varga',
                title: 'Schwerpunkte',
                text: 'Als Gefässchirurg ist Dr. Michael Szente Varga spezialisiert auf Durchblutungsstörungen der Becken- und Beinschlagadern, Verengungen der Halsschlagader, Erweiterungen der Bauchschlagader, Krampfadern, Thrombosen und offene Beine. Dabei wendet er verschiedene minimal-invasive Verfahren an, wie die Ballondilatation, Stenteinlagen, und die Krampfaderbehandlung. Seine operativen Verfahren umfassen Bypassoperationen, Thrombendarterektomien, der Ersatz der Bauchschlagader, das Anlegen von AV-Fisteln für die Dialyse sowie das Stripping von Krampfadern. Schwerpunktsmässig steht die Behandlung der Erweiterung der Brust- und Bauchschlagader mittels endovaskulärer Verfahren im Vordergrund.',
              },
            ]}
          />

        </Layout>

        <ContactSectionOuter>
          <ContactSectionInner>
            <ContactSection doctor='dr-varga' title='Contact Dr. Varga' background="#213fca" mailTitle="Dr. med. Michael Szente Varga" />
          </ContactSectionInner>
        </ContactSectionOuter>

        <Footer />
      </>
    )
  }
}

export default DrVargaPage
